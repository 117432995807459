@import "../styles/breakpoints.scss";

.home-page {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  -webkit-animation: fade-in 0.5s linear; /* Safari 4+ */
  -moz-animation: fade-in 0.5s linear; /* Fx 5+ */
  -o-animation: fade-in 0.5s linear; /* Opera 12+ */
  animation: fade-in 0.5s linear; /* IE 10+, Fx 29+ */
  & h1 {
    font-size: 52px;
    line-height: 1;
    font-weight: 300;
    text-align: center;
  }
  &__description {
    max-width: 700px;
    text-align: center;
    font-size: 24px;
  }

  @include for-tablet-only {
    & h1 {
      padding: 0 0.5em;
    }
    &__description {
      padding: 0 0.5em;
    }
  }
  @include for-mobile-only {
    & h1 {
      padding: 0 0.5em;
    }
  }
  &__description {
    padding: 0 0.5em;
  }
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fade-in {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

@import "../../styles/breakpoints.scss";

.main-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 12.5rem;
  height: 100%;
  padding: 0px 3em;
  & img{
    cursor: pointer;
  }
  &__info {
    font-size: 24px;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    align-items: center;
    &--social{
      width: 100%;
      padding-top: 10px;
      display: flex;
      justify-content: space-evenly;
    }
  }
  @include for-desktop-only {
    flex-direction: column;
    align-items: center;
    max-height: none;
    &__info {
      & div {
        text-align: center;
      }
    }
  }
  @include for-tablet-only {
    flex-direction: column;
    align-items: center;
    max-height: none;
    &__info {
      & div {
        text-align: center;
      }
    }
    & img {
      height: 15em;
    }
  }
  @include for-mobile-only {
    flex-direction: column;
    align-items: center;
    max-height: none;
    &__info {
      & div {
        text-align: center;
      }
    }
    & img {
      height: 10em;
    }
  }
}

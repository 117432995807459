.button {
  width: 100%;
  & button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding: 16px;
    border-radius: 4px;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
    height: 52px;
    width: 100%;
    transition: all ease-in 0.2s;
    outline: 0;
    & svg{
      padding-left: 5px;
    }
  }
  &.default button {
    background: #444b5b;
    color: #ffffff;
    &:hover {
      background: #2f333d;
    }
  }
  &.submit button {
    background: #444b5b;
    color: #ffffff;
    &:hover {
      background: #2f333d;
    }
  }
  button:disabled,
  button[disabled] {
    background-color: #cccccc;
    color: #666666;
    cursor: default;
    &:hover {
      background: #cccccc;
    }
  }
}

.order-page {
  padding: 0 1em;
  -webkit-animation: fade-in 0.5s linear; /* Safari 4+ */
  -moz-animation: fade-in 0.5s linear; /* Fx 5+ */
  -o-animation: fade-in 0.5s linear; /* Opera 12+ */
  animation: fade-in 0.5s linear; /* IE 10+, Fx 29+ */
  &__loading{
     display: flex;
     justify-content: center;
     margin-top: 2em;
     min-height: 288px;
  }
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fade-in {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

.cart-item {
  display: flex;
  padding: 0.5em 0.5em;
  & img {
      height: 100%;
      max-height: 60px;
      
  }
  &__description {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    padding-left: 1em;
    &-header {
      font-weight: 600;
    }
    &-article {
      font-weight: 600;
      & span {
        font-size: 14px;
        font-weight: 400;
        color: rgb(65, 65, 65) (122, 116, 116);
      }
    }
    &-price{
  
        font-weight: 600;
      & span {
        font-size: 14px;
        font-weight: 400;
        color: rgb(65, 65, 65) (122, 116, 116);
      }
    }
  }
  &__buttons{
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      & .button-icon{
        padding: 3px;
      }
  }

}
.item{
    padding: 0.5em 0;
    display: flex;
    justify-content: space-between;
    border-image: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(110,110,110,1) 50%, rgba(255,255,255,1) 100%);
    border-bottom: 1px solid transparent;
    border-image-slice: 1;
}
.icon{
    &-link{
        max-width: 1.1em;
        max-height: 1.1em;;
        cursor: pointer;

        & img{
            max-width: 1.1em;;
            max-height: 1.1em;;
        }
    }
    
}
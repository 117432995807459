@import "../../../styles/breakpoints.scss";

.about-block {
  margin-top: 2em;
  padding: 3em 0;
  background-color: #eeeeee;
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-evenly;
  @include for-desktop-only {
    flex-wrap: wrap;
  }
  @include for-tablet-only {
    flex-wrap: wrap;
  }
  @include for-mobile-only {
    flex-wrap: wrap;
}
}

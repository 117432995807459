$dimension: 'px';
$sm: 768;
$md: 1024;
$ld: 1366;

$ldMin: $ld + $dimension;

$mdMin: $md + $dimension;
$mdMax: ($ld - 1) + $dimension;

$smMin: $sm + $dimension;
$smMax: ($md - 1) + $dimension;

$xsMax: ($sm - 1) + $dimension;

@mixin for-mobile-only {
	@media (max-width: #{$xsMax}) {
		@content;
	}
}

@mixin for-tablet-only {
	@media (min-width: $smMin) and (max-width: $smMax) {
		@content;
	}
}

@mixin for-desktop-only {
	@media (min-width: #{$mdMin}) and (max-width: #{$mdMax}) {
		@content;
	}
}

@mixin for-large-desktop-only {
	@media (max-width: #{$xsMax}) {
		@content;
	}
}

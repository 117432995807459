.schedule{
    background-color: #eeeeee;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 1em;
    font-weight: 600;
    border-bottom: 0.5px solid rgb(223, 218, 218);
}
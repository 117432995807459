.footer{
    background: #444b5b;
    padding: 1em 0;
    width: 100%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    & div{
        padding-left: 1em;
    }
}


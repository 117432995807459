@import url(https://fonts.googleapis.com/css?family=Roboto:300&display=swap);
.home-page{display:flex;justify-content:center;flex-direction:column;align-items:center;-webkit-animation:fade-in .5s linear;animation:fade-in .5s linear}.home-page h1{font-size:52px;line-height:1;font-weight:300;text-align:center}.home-page__description{max-width:700px;text-align:center;font-size:24px}@media(min-width: 768px)and (max-width: 1023px){.home-page h1{padding:0 .5em}.home-page__description{padding:0 .5em}}@media(max-width: 767px){.home-page h1{padding:0 .5em}}.home-page__description{padding:0 .5em}@-webkit-keyframes fade-in{0%{opacity:.1}100%{opacity:1}}@keyframes fade-in{0%{opacity:.1}100%{opacity:1}}
.service-block{display:flex;padding:5em 8em;align-items:baseline;flex-wrap:wrap;justify-content:center}@media(min-width: 768px)and (max-width: 1023px){.service-block{padding:0}}@media(max-width: 767px){.service-block{padding:0}}
.service-item{padding:2em;display:flex;flex-direction:column;align-items:center;justify-content:center;height:100%}.service-item__img{width:10em;height:10em;max-width:100%;max-height:10em;background-size:cover;background-repeat:no-repeat;background-position:center;border-radius:50%}.service-item__header{font-size:20px;padding-bottom:1em}.service-item__description{font-size:14px;color:#7a7a7a;max-width:235px}
.about-block{margin-top:2em;padding:3em 0;background-color:#eee;width:100%;display:flex;align-items:baseline;justify-content:space-evenly}@media(min-width: 1024px)and (max-width: 1365px){.about-block{flex-wrap:wrap}}@media(min-width: 768px)and (max-width: 1023px){.about-block{flex-wrap:wrap}}@media(max-width: 767px){.about-block{flex-wrap:wrap}}
.about-item{display:flex;flex-direction:column;align-items:center}.about-item__header{font-size:30px;line-height:1.17;font-weight:600}.about-item__img{background-size:cover;width:6em;height:6em}.about-item__description{padding-top:1em;max-width:18.75rem;text-align:center;font-weight:300;font-size:18px;line-height:1.55;font-family:"Roboto",sans-serif}@media(min-width: 768px)and (max-width: 1023px){.about-item{margin:1em 0}}@media(max-width: 767px){.about-item{flex-wrap:wrap;margin:1em 0}}
.schedule{background-color:#eee;width:100%;display:flex;align-items:center;justify-content:center;flex-direction:column;padding-top:1em;font-weight:600;border-bottom:.5px solid #dfdada}
.order-page{padding:0 1em;-webkit-animation:fade-in .5s linear;animation:fade-in .5s linear}.order-page__loading{display:flex;justify-content:center;margin-top:2em;min-height:288px}@-webkit-keyframes fade-in{0%{opacity:.1}100%{opacity:1}}@keyframes fade-in{0%{opacity:.1}100%{opacity:1}}
.customer-cart{margin-top:2em}.customer-cart__header{font-size:19px;font-weight:500;display:flex;align-items:flex-start;padding-bottom:1em}.customer-cart__cart{border-top:1px solid #a5a2a2;border-left:1px solid transparent;border-right:1px solid transparent;border-image:linear-gradient(to bottom, #a5a2a2, #fdfdfd);border-image-slice:1;width:100%}.customer-cart__empty{width:100%;text-align:center;padding:3em 0;font-size:40px;min-height:230px}
.cart-item{display:flex;padding:.5em .5em}.cart-item img{height:100%;max-height:60px}.cart-item__description{display:flex;flex-direction:column;align-items:baseline;padding-left:1em}.cart-item__description-header{font-weight:600}.cart-item__description-article{font-weight:600}.cart-item__description-article span{font-size:14px;font-weight:400;color:#414141 122,116,116}.cart-item__description-price{font-weight:600}.cart-item__description-price span{font-size:14px;font-weight:400;color:#414141 122,116,116}.cart-item__buttons{display:flex;flex-direction:column;justify-content:space-around;align-items:center}.cart-item__buttons .button-icon{padding:3px}.item{padding:.5em 0;display:flex;justify-content:space-between;border-image:linear-gradient(90deg, white 0%, #6e6e6e 50%, white 100%);border-bottom:1px solid transparent;border-image-slice:1}
.customer-form form{margin:1em 0;padding-top:1em;display:flex;flex-direction:column}
.button{width:100%}.button button{display:flex;align-items:center;justify-content:center;border:none;padding:16px;border-radius:4px;text-align:center;line-height:20px;cursor:pointer;height:52px;width:100%;transition:all ease-in .2s;outline:0}.button button svg{padding-left:5px}.button.default button{background:#444b5b;color:#fff}.button.default button:hover{background:#2f333d}.button.submit button{background:#444b5b;color:#fff}.button.submit button:hover{background:#2f333d}.button button:disabled,.button button[disabled]{background-color:#ccc;color:#666;cursor:default}.button button:disabled:hover,.button button[disabled]:hover{background:#ccc}
.subtotal{padding-top:2em}.subtotal span{font-weight:700}
.zzap-link{position:absolute;right:0;top:0}.zzap-link span{font-weight:600;cursor:pointer}
.main-header{display:flex;justify-content:space-between;align-items:center;max-height:12.5rem;height:100%;padding:0px 3em}.main-header img{cursor:pointer}.main-header__info{font-size:24px;font-weight:300;display:flex;flex-direction:column;align-items:center}.main-header__info--social{width:100%;padding-top:10px;display:flex;justify-content:space-evenly}@media(min-width: 1024px)and (max-width: 1365px){.main-header{flex-direction:column;align-items:center;max-height:none}.main-header__info div{text-align:center}}@media(min-width: 768px)and (max-width: 1023px){.main-header{flex-direction:column;align-items:center;max-height:none}.main-header__info div{text-align:center}.main-header img{height:15em}}@media(max-width: 767px){.main-header{flex-direction:column;align-items:center;max-height:none}.main-header__info div{text-align:center}.main-header img{height:10em}}
.icon-link{max-width:1.1em;max-height:1.1em;cursor:pointer}.icon-link img{max-width:1.1em;max-height:1.1em}

html{font-family:"Roboto",sans-serif}body{margin:0}
.main-button{max-width:20em;margin:20px auto}.main-button span{font-size:17px}
.footer{background:#444b5b;padding:1em 0;width:100%;color:#fff;display:flex;justify-content:center;align-items:center;font-size:14px}.footer div{padding-left:1em}
.error{position:fixed;top:0;z-index:100;width:100%;opacity:0;-webkit-animation-fill-mode:forwards;animation-fill-mode:forwards;-webkit-animation:show 10s 1;animation:show 10s 1}@-webkit-keyframes show{0%{opacity:0}15%{opacity:.9}100%{opacity:1}}@keyframes show{0%{opacity:0}15%{opacity:.9}100%{opacity:1}}

@import "../../../../styles/breakpoints.scss";

.about-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__header {
    font-size: 30px;
    line-height: 1.17;
    font-weight: 600;
  }
  &__img {
    background-size: cover;
    width: 6em;
    height: 6em;
  }
  &__description {
    padding-top: 1em;
    max-width: 18.75rem;
    text-align: center;
    font-weight: 300;
    font-size: 18px;
    line-height: 1.55;
    font-family: 'Roboto', sans-serif;
  }
  @include for-tablet-only {
    margin: 1em 0;
  }
  @include for-mobile-only {
    flex-wrap: wrap;
    margin: 1em 0;
}
}

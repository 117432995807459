@import '../../../styles/breakpoints.scss';

.customer-cart {
  margin-top: 2em;
  &__header {
    font-size: 19px;
    font-weight: 500;
    display: flex;
    align-items:flex-start;
    padding-bottom: 1em;
  }
  &__cart {
    border-top: 1px solid rgb(165, 162, 162);
    border-left:1px solid transparent;
    border-right: 1px solid transparent;
    border-image: linear-gradient(to bottom, rgb(165, 162, 162) , rgb(253, 253, 253));
    border-image-slice: 1;
    width: 100%;
  }
  &__empty {
    width: 100%;
    text-align: center;
    padding: 3em 0;
    font-size: 40px;
    min-height: 230px;

  }

}

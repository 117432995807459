.error {
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    opacity: 0;
    animation-fill-mode: forwards;
    animation: show 10s 1;
}
@keyframes show {
    0% {
        opacity: 0;
    }
    15% {
        opacity: 0.9;
    }
    100% {
        opacity: 1;
    }
}

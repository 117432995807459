.service-item{
    padding: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    &__img{
        
        width: 10em;
        height: 10em;
        max-width: 100%;
        max-height: 10em;
        // padding-bottom: 60%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 50%;
    }
    &__header{
        font-size: 20px;
        padding-bottom: 1em;
    }
    &__description{
        font-size: 14px;
        color: #7a7a7a;
        max-width: 235px;
    }
}
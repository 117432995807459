@import "../../../styles/breakpoints.scss";

.service-block {
  display: flex;
  padding: 5em 8em;
  align-items: baseline;
  flex-wrap: wrap;
  justify-content: center;
  @include for-tablet-only {
    padding: 0;
  }
  @include for-mobile-only {
    padding: 0;
  }
}
